export const menu = [
  {
    label: 'SOLUCIONES',
    path: '/#soluciones',
  },
  {
    label: 'CASOS DE ÉXITO',
    path: '/#exito',
  },
  {
    label: 'CONTÁCTENOS',
    path: '/#contacto',
  }
]
