export default {
  initialColorModeName: 'light',
  colors: {
    text: '#333',
    background: '#ffffff',
    primary: '#352045',
    secondary: '#642596',
    border: '#e6e6e6',
    navlink: '#333',
    bg_topbar: '',
    bg_header: '#fff',
    bg_mobileMenu: '#fff',
    bg_sideNav: '',
    bg_footer: '#352045',
  },
  fonts: {
    body: 'Bahnschrift Regular, sans-serif',
    heading: 'Bahnschrift Regular, sans-serif',
  },
  breakpoints: ['768px', '960px', '1240px'],
  styles: {
    root: {
      fontFamily: 'body',
      a: {
        textDecoration: 'none',
      },
    },
  },
  // ---------------------------
  //
  //  Maker UI layout variants
  //
  //  See: https://maker-ui.dev/layout-components for a complete list of variants
  //
  // ---------------------------
  header: {
    py: 15,
    px: 3,
    borderBottom: '1px solid',
    borderColor: 'border',
    fontFamily: 'heading',
    boxShadow: 'rgba(35, 68, 101, 0.1) 0px 4px 4px, rgba(35, 68, 101, 0.1) 0px 4px 16px',
    logo: {
      fontSize: [16, 24],
      fontWeight: 'bold',
      a: { color: 'navlink' },
    },
    menu: {
      px: 20,
      display: 'flex',
      a: {
        fontSize: 4,
        display: 'flex',
        alignItems: 'center',
        color: 'navlink',
        p: 3,
        '.menu-link-icon': {
          display: 'flex',
          mr: '5px',
          fill: 'currentColor',
        },
      },
    },
    navbar: {},
    submenu: {
      bg: 'bg_header',
      boxShadow: '0px 4px 16px rgba(46, 41, 51, 0.08)',
      border: t => `1px solid ${t.colors.border}`,
      borderBottom: 'none',
      a: {
        px: 30,
        color: 'text',
        borderBottom: t => `1px solid ${t.colors.border}`,
        transition: 'all ease .3s',
        '&:hover': {
          bg: 'background',
        },
      },
    },
    menuButton: {},
    widgets: {},
  },
  mobileNav: {},
  sideNav: {},
  main: {
    titleBorder: {
      fontSize: [25, 35],
      mb: [10, 20],
      textAlign: 'center',
    }
  },
  buttons: {
    primary: {
      fontWeight: 'bold',
      fontFamily: 'body',
      py: 15,
      px: 20,
      color: 'white',
      bg: '#398CCB',
      '&:hover': {
        cursor: 'pointer',
        color: 'white',
        bg: '#5aa2e3',
      },
    },
    secondary: {
      fontWeight: 'bold',
      fontFamily: 'body',
      py: 15,
      px: 20,
      color: 'white',
      bg: '#ea504c',
      '&:hover': {
        cursor: 'pointer',
        color: 'white',
        bg: '#fc7371',
      },
    },
  },
  footer: {
    textAlign: 'center',
    color: 'white',
    p: {
      width: '100%'
    },
    a: {
      color: 'white'
    }
  }
}
