/** @jsx jsx */
import { config } from '@fortawesome/fontawesome-svg-core'
import { Link } from "gatsby"
import { Content, Footer, Header, Layout, Main, MobileMenu, Navbar } from 'maker-ui'
import { Box, jsx } from 'theme-ui'
import logo from '../../static/images/logos/icomm_logo.png'
import { menu } from '../config/menus'
import options from '../config/options'
import theme from '../config/theme'

config.autoAddCss = false

export default ({ children, location }) => (
    <Layout theme={theme} options={options} id="top">
        <Header>
            <Navbar
                logo={
                    <img
                        sx={{ maxHeight: [60, 74] }}
                        src={logo}
                        alt="iComm Solutions"
                    />
                }
                menu={menu}
            />
            <MobileMenu menu={menu} pathname={location.pathname} />
        </Header>
        <Content>
            <Main>
                {children}
            </Main>
        </Content>
        <Footer
            sx={{
                py: [4, 5],
                px: [3, 4],
            }}>
            <Box as="p" sx={{ fontSize: [14, 18], mb: 2 }}>
                © 2022 iComm Solutions S.A.S.
                <br />
                Powered by <a
                    href="http://inappsis.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    Inappsis
                </a>
                <br />
                <Link to="/privacy-policy">Política de Tratamiento de Datos Personales</Link>
            </Box>
        </Footer>
    </Layout>
)