module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"iComm Solutions","short_name":"iComm","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/images/logos/icomm_icon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e47799238ec664700bc1946d83328cf2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/icomm-landing-frontend/icomm-landing-frontend/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-179150691-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"cookieDomain":"i-comm.co"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
